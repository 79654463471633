import { useState, lazy, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './styles.less'
import Request from '../../request'
import _ from 'lodash';
import { PhoneOutlined } from '@ant-design/icons';
import moment from 'moment'
import { notification, List, Avatar, Button, Skeleton, Form } from 'antd';
import FormItem from '../../components/FormItem'
import { DatePicker, Space } from 'antd';
import UpgradePlan from '../upgradePlan/upgradePlan'
import CallingInfo from '../callingSidebar/callingInfo';

const { RangePicker } = DatePicker;

const HomeView = (props) => {

    const [loading, setLoader] = useState(false);
    const [list, setDataSource] = useState('');
    const [pageTotal, setPageTotal] = useState();
    const [countries, setCountries] = useState([])
    const [Callnotes, setCallnotes] = useState(true)
    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const apiFunction = async (pageNum) => {
        setLoader(true)
        let { user, token, success, message, data, total } = await Request.listCallNotes({ ...pageNum })
        setLoader(false)
        if (success) {
            setDataSource(data)
            setPageTotal(total)
        } else {
            setDataSource([])

            // notification.error({
            //   message: message || "Failed"
            // })
        }
    }

    const onFinish = async (valData) => {
        console.log(valData, ">>>>>>>>>>>")
        // valData = {
        //     calledphone: valData?.phonecode + valData?.calledphone,
        //     phonecode: valData?.phonecode,
        //     notesdata: valData?.notesdata
        // }
        setLoader(true)
        let { user, token, success, message, data } = await Request.addCallNotes(valData)
        setLoader(false)
        if (success) {
            notification.success({
                message: message
            })
            form.resetFields()
            apiFunction()
            setCallnotes(true)
        } else {
            notification.error({
                message: message || "Failed",
                description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')
            })
        }
    }

    useEffect(() => {
        apiFunction()
        form.setFieldsValue({ country: 'IN' });
    }, [])

    const requiredRule = [{ required: true, message: 'Please input field' }]

    const { currentUser } = useSelector((state) => ({
        currentUser: state.global.currentUser
    }))

    if (!currentUser?.balance[0]?.callnotes) {
        return <UpgradePlan />
    }

    return <>
        <div className='headerInfo'>
            Call Notes
            <CallingInfo />
        </div>
        <div className='wrapperTop'>
            <div className="buttonDiv1">
                <div className={Callnotes ? 'selectedDiv' : 'unselectedDiv'}>
                    <Button size="large" ghost={!Callnotes} type={Callnotes ? "primary" : "secondary"} shape="round"
                        onClick={() => { setCallnotes(true) }} className={Callnotes ? 'selectedDivButton' : 'unselectedDivButton1'}>Callnotes</Button>
                </div>
                <div className={!Callnotes ? 'selectedDiv' : 'unselectedDiv'}>
                    <Button size="large" ghost={Callnotes} type={!Callnotes ? "primary" : "secondary"} shape="round"
                        onClick={() => { setCallnotes(false); form.setFieldsValue({ country: 'IN' }) }} className={!Callnotes ? 'selectedDivButton' : 'unselectedDivButton1'}>Add Callnotes</Button>
                </div>
            </div>
            {Callnotes && <List
                className="demo-loadmore-list"
                loading={loading}
                itemLayout="horizontal"
                dataSource={list}
                pagination={{
                    onChange: page => {
                        apiFunction({ page: page })
                    },
                    pageSize: 20,
                    total: pageTotal,
                    hideOnSinglePage: true,
                    showSizeChanger: false
                }}
                renderItem={item => (
                    <List.Item>
                        <Skeleton avatar title={false} loading={item.loading} active>
                            <div style={{ display: 'flex', width: '75%', overflowWrap: 'anywhere', flexFlow: 'column' }}>
                                <div>{'+' + item?.phonecode + ' ' + item?.calledphone}</div>
                                <div style={{ color: '#00000073' }}>{item?.notesdata}</div>
                            </div>
                            <div style={{ marginLeft: 'auto' }}>{moment.utc(item?.createdon).format('LLL')}</div>
                        </Skeleton>
                    </List.Item>
                )}
            />}
            {!Callnotes && <div className='buttonSubmit'>
                <Form
                    form={form}
                    onFinish={onFinish}
                >

                    <FormItem
                        label={'Phone Code'}
                        name="phonecode"
                        rules={requiredRule}
                    />
                    <FormItem
                        label={'Phone Number'}
                        name="calledphone"
                        maxLength={12}
                        rules={requiredRule}
                    />
                    <FormItem
                        label={'Notes'}
                        name="notesdata"
                        initialValues="IN"
                        inputType={'TEXTAREA'}
                        rules={requiredRule}
                    />
                    <FormItem
                        inputType="BUTTON"
                        class={'buttonLayT'}
                        type="primary"
                        buttonStyle={{ width: 200, marginTop: 20 }}
                        loading={loading}
                        title="SUBMIT"
                        htmlType="submit" />
                </Form>
            </div>}
        </div >
    </>
}

export default HomeView;