import { useState, lazy, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './styles.less'
import Request from '../../request'
import _ from 'lodash';
import { PhoneOutlined } from '@ant-design/icons';
import FilterTable from '../../components/Table'
import moment from 'moment'
import { notification, List, Avatar, Button, Skeleton, Form } from 'antd';
import FormItem from '../../components/FormItem'
import CallingInfo from '../callingSidebar/callingInfo';

// const Calltype = lazy(() => import('./callTypeChart'))
// const LiveCallsTable = lazy(() => import('./liveCallsTable'))
// const layout = {
//   labelCol: {
//     span: 8,
//   },
//   wrapperCol: {
//     span: 16,
//   },
// };
// const tailLayout = {
//   wrapperCol: {
//     offset: 10,
//     span: 16,
//   },
// };

const HomeView = (props) => {

  const [loading, setLoader] = useState('false');
  const [dataSource, setDataSource] = useState('');
  const [countries, setCountries] = useState([])
  const [pageTotal, setPageTotal] = useState();
  const [contacts, setContacts] = useState(true)
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const apiFunction = async (pageNum) => {
    setLoader(true)
    let { user, token, success, message, data, total } = await Request.getContacts({ ...pageNum })
    setLoader(false)
    if (success) {
      setDataSource(data)
      setPageTotal(total)
    } else {
      setDataSource([])

      // notification.error({
      //   message: message || "Failed"
      // })
    }
  }

  const onFinish = async (valData) => {
    setLoader(true)
    let { user, token, success, message, data } = await Request.addContact(valData)
    // console.log(success, data, message)
    setLoader(false)
    if (success) {
      notification.success({
        message: message
      })
      form.resetFields()
      apiFunction()
      setContacts(true)
    } else {
      notification.error({
        message: message || "Failed",
        description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')
      })
    }
  }

  useEffect(() => {
    apiFunction()
    getFunction()
    form.setFieldsValue({ country: 'IN' });
  }, [])

  const getFunction = async () => {
    let { user, token, success, data } = await Request.getCountries()
    if (success) {
      setCountries(() => data)
    } else {
      setCountries(() => [])

    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      search: false,
      fixed: 'left',
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      key: 'mobile',
    },
    {
      title: 'Created At',
      dataIndex: 'createdon',
      key: 'createdon',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.createdon.localeCompare(b.createdon),
      render: (val, r) => {
        return (moment.utc(val).format('DD-MM-YYYY'))
      }
    },

    {
      title: 'Actions',
      dataIndex: 'Action',
      key: 'Action',
      render: (val, record) => {
        return <div className="flex" style={{ justifyContent: 'center' }}>
          <PhoneOutlined className='greenCallButton' onClick={() => { dispatch({ type: 'SET_NUMBER_FROM_CONTACTS', payload: { phone: record?.mobile, leadsetid: record?.leadsetid } }) }} />
        </div>
      }
    },
  ];

  const requiredRule = [{ required: true, message: 'Please input field' }]

  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser
  }))

  return <>
    <div className='headerInfo'>
      Contacts
      <CallingInfo />
    </div>
    <div className='wrapperTop'>
      <div className="buttonDiv1">
        <div className={contacts ? 'selectedDiv' : 'unselectedDiv'}>
          <Button size="large" ghost={!contacts} type={contacts ? "primary" : "secondary"} shape="round"
            onClick={() => { setContacts(true) }} className={contacts ? 'selectedDivButton' : 'unselectedDivButton1'}>Contacts</Button>
        </div>
        <div className={!contacts ? 'selectedDiv' : 'unselectedDiv'}>
          <Button size="large" ghost={contacts} type={!contacts ? "primary" : "secondary"} shape="round"
            onClick={() => { setContacts(false); form.setFieldsValue({ country: 'IN' }) }} className={!contacts ? 'selectedDivButton' : 'unselectedDivButton1'}>Add Contacts</Button>
        </div>
      </div>
      {contacts && <div className="marginTop" style={{ width: '45vw', margin: '0 auto' }}>
        <FilterTable bordered={false} pagination={{
          onChange: page => {
            apiFunction({ page: page })
          },
          pageSize: 20,
          total: pageTotal,
          hideOnSinglePage: true,
          showSizeChanger: false
        }} dataSource={dataSource} size="large" columns={columns} loading={loading} scroll={{ x: 'max-content' }} />
      </div>}
      {!contacts && <div className='buttonSubmit'>
        <Form
          // {...layout}
          form={form}
          onFinish={onFinish}
        >

          <FormItem
            label={'Contact Name'}
            name="name"
            rules={requiredRule}
          />
          <FormItem
            label={'Phone Number'}
            name="mobile"
            maxLength={11}
            rules={requiredRule}
          />
          <FormItem
            label={'Email Id'}
            name="email"
            inputType={'EMAIL'}
            rules={requiredRule}
          />
          <FormItem
            label={'Address'}
            name="address"
          />
          <FormItem
            label={'Country'}
            name="country"
            initialValues="IN"
            rules={requiredRule}
            inputType={"SELECT"}
            options={_.map(countries, val => { return ({ label: val?.countryname, value: val?.countrycode }) })}
          />
          <FormItem
            inputType="BUTTON"
            type="primary"
            buttonStyle={{ width: 200, marginTop: 20 }}
            loading={loading}
            title="SUBMIT"
            htmlType="submit" />
        </Form>
      </div>}
    </div >
  </>
}

export default HomeView;