import React from 'react'
import { push } from 'connected-react-router'
import _ from 'lodash'
import { Path } from 'path-parser'


import Dashboard from './containers/home'
import Call from './containers/call'
import Callhistory from './containers/callHistory'
import Contacts from './containers/contacts'
import Callbacks from './containers/callbacks'
import Callnotes from './containers/callnotes'
import { ReactComponent as Dialerplanner } from './assets/dialerPlanner.svg'
import { ReactComponent as Dialersms } from './assets/dialersms.svg'

import { HomeOutlined, PhoneOutlined, ContactsOutlined, HistoryOutlined } from '@ant-design/icons'
import { ReactComponent as Number } from './assets/Number.svg'
import { ReactComponent as User } from './assets/user.svg'


const menu = [
  {
    'path': '/dashboard',
    'name': '',
    'title': "Home",
    'icon': <HomeOutlined fill="black" />,
    'key': 'home',
    'component': <Dashboard />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/call',
    'name': '',
    'title': "Call",
    'icon': <PhoneOutlined />,
    'key': 'Call',
    'component': <Call />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/callhistory',
    'name': '',
    'title': "callhistory",
    'icon': <HistoryOutlined />,
    'key': 'callhistory',
    'component': <Callhistory />,
    'authority': [
      'user'
    ]
  },
  // {
  //   'path': '/voicemail',
  //   'name': 'Voicemail',
  //   'title': "Voicemail",
  //   'icon': <User />,
  //   'key': 'Voicemail',
  //   'component': <Voicemail />,
  //   'authority': [
  //     'user'
  //   ]
  // },
  {
    'path': '/Contacts',
    'name': '',
    'title': "Contacts",
    'icon': <ContactsOutlined />,
    'key': 'Contacts',
    'component': <Contacts />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/Callbacks',
    'name': '',
    'title': "Callbacks",
    'icon': <Dialerplanner />,
    'key': 'Callbacks',
    'component': <Callbacks />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/Callnotes',
    'name': '',
    'title': "Callnotes",
    'icon': <Dialersms />,
    'key': 'Callnotes',
    'component': <Callnotes />,
    'authority': [
      'user'
    ]
  },
]

// utils for path





export const getUrlPushWrapper = (keyString, query) => {
  return push(getUrlPath(keyString, query))
}

export const getUrlPath = (keyString, params) => {

  if (!params) params = {}

  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')

  return `${val.path}?${queryString}`
}

export const getPushPathWrapper = (keyString, params = {}) => {

  let obj = getUrlObject(keyString)

  if (obj) {
    const path = new Path(obj.path)
    return push(path.build(params))
  }

  return 'error'
}

export const getUrlParams = (keyString, route) => {

  let obj = getUrlObject(keyString)
  if (obj) {
    const path = new Path(obj.path)

    return path.test(route)
  }

  return { error: true }
}

export const getUrlObject = (keyString) => {

  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  return val
}

export default menu;