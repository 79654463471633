import { useState, lazy, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import './styles.less'
import Request from '../../request'
import _ from 'lodash';
import async from 'async'
import moment from 'moment'
import Highcharts from "highcharts/highstock";
import PieChart from "highcharts-react-official";
import TodayCallBacks from '../callbacks/TodayCallBacks'
import CallHistory from '../callHistory/index'
import TodaysCallList from './todaysCallList.js'
import { ReactComponent as TotalCalls } from '../../assets/totalCalls.svg'
import { ReactComponent as CallTime } from '../../assets/callTime.svg'
import { ReactComponent as LoginTime } from '../../assets/loginTime.svg'
import { ReactComponent as PauseTime } from '../../assets/pauseTime.svg'
import { notification, Drawer, Input, Tag, Tooltip, Tabs, Modal, Skeleton, Button, Select } from 'antd';
// import sound from '../../assets/sound.wav'
import CallingInfo from '../callingSidebar/callingInfo';

// const Calltype = lazy(() => import('./callTypeChart'))
// const LiveCallsTable = lazy(() => import('./liveCallsTable'))

const HomeView = (props) => {

  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(true)
  const [loader1, setLoader1] = useState(true)
  const [todaysDispositions, setTodaysDispositions] = useState([{
    name: 'No Data',
    y: 0,
    sliced: false,
    selected: true
  }])
  const [callerDetails, setCallerDetails] = useState()
  const [callList, setCallList] = useState()
  // const [callTime, setCallTime] = useState(parseInt('00:00:00'))
  // const [pauseTime, setPauseTime] = useState(parseInt('00:00:00'))
  // const [readyTime, setReadyTime] = useState(parseInt('00:00:00'))

  const { TabPane } = Tabs;

  const { currentUser, todaysCallbacks } = useSelector((state) => ({
    currentUser: state.global.currentUser,
    todaysCallbacks: state.global.todaysCallbacks
  }))

  const options = {
    chart: {
      type: "pie"

    },
    title: false,
    credits: {
      enabled: false
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>:<br>{point.percentage:.1f} %<br />',
        },
        showInLegend: {
          display: true,
          position: "right"
        },
        format: '{point.percentage:.1f}'
      }
    },
    series: [
      {
        colors: ['#20B2AA', '#4169E1', '#A52A2A', '#FFD700', '#663399', '#32CD32', '#FFA500', '#BDB76B', '#E6E6FA', ' #006400', '#00FFFF', '#BC8F8F', '#BC8F8F', '#FFE4E1', '#696969', '#000000', '#AFEEEE', '#3CB371', '#000080', '#808000', '#1E90FF', '#DC143C', '#FF69B4', '#FF4500'],
        lineWidth: 1,
        marker: {
          enabled: false,
          symbol: "square",
          radius: 4,
          states: {
            hover: {
              enabled: true,
              lineWidth: 1
            }
          }
        },
        animation: false,
        data: todaysDispositions.length ? todaysDispositions : [{
          name: 'No Data',
          y: 1,
          sliced: false,
          selected: true
        }]
      }
    ]
  };

  const callerDetail = async (val) => {
    setLoader(true)
    let { success, message, data, total } = await Request.callerDetail()
    setLoader(false)
    if (success) {
      setCallerDetails(data)
    } else {
      setCallerDetails([])
    }
  }

  const todayCallList = async (val) => {
    setLoader(true)
    let { success, message, data, total } = await Request.todayCallList()
    setLoader(false)
    if (success) {
      setCallList(total)
    } else {
      setCallList()
    }
  }

  const updateDisplay = (dateValue) => {
    const dateVal = dateValue * 60;
    var myDate = new Date(null, null, null, null, null, dateVal).toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1");
    return myDate;
  }

  const todayDispositions = async (val) => {
    setLoader1(true)
    let { success, message, data, total } = await Request.todayDispositions()
    setLoader1(false)
    console.log(data, ">>>>>>>>>>><<<<<<<")
    if (success) {
      let newData = []
      async.forEach(data, (val, cb) => {
        let obj = val
        obj = {
          name: obj?.dispositions?.name,
          y: obj?.status__count,
          sliced: false,
          selected: true
        }
        newData.push(obj)
        cb()
      }, () => {
        setTodaysDispositions(newData)
      })
    }
    else {
      setTodaysDispositions([])
    }
  }

  useEffect(() => {
    callerDetail()
    todayCallList()
    todayDispositions()
  }, [])

  useEffect(() => {
    if (todaysCallbacks == false) {
      setVisible(false)
    }
    else {
      setVisible(true)
    }
  }, [todaysCallbacks])

  // useEffect(() => {
  //   setCallTime(updateDisplay(callerDetails?.calltotaltime || 0))
  //   setPauseTime(updateDisplay(callerDetails?.pausetotaltime || 0))
  //   setReadyTime(updateDisplay(callerDetails?.readytotaltime || 0))
  // }, [callerDetails])

  return <>
    <div className='headerInfo'>
      Dashboard
      <CallingInfo />
    </div>
    <div style={{ padding: '10px 5px 5px 5px' }}>
      <div className='flex' style={{ width: '100%', columnGap: '10px' }}>
        <div className="widt100 marTop" style={{ width: 'inherit' }}>
          <div className="flex jcsb" style={{ background: 'white', borderRadius: '12px' }}>
            <div className="summaryCard summaryCard1">
              <div>
                <div className="dashSubValueSummary">Total Calls</div>
                <div className="dashUserSummary">{callList}</div>
              </div>
              <div>
                <TotalCalls />
              </div>
            </div>
            <div className="summaryCard summaryCard1">
              <div>
                <div className="dashSubValueSummary">Call Time</div>
                <div className="dashUserSummary">{callerDetails?.calltotaltime}</div>
              </div>
              <div>
                <CallTime />
              </div>
            </div>
            <div className="summaryCard summaryCard1">
              <div>
                <div className="dashSubValueSummary">Login Time</div>
                <div className="dashUserSummary">{callerDetails?.readytotaltime}</div>
              </div>
              <div>
                <LoginTime />
              </div>
            </div>
            <div className="summaryCard">
              <div>
                <div className="dashSubValueSummary">Pause Time</div>
                <div className="dashUserSummary">{callerDetails?.pausetotaltime}</div>
              </div>
              <div>
                <PauseTime />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex jcsb homeHistory">
        <div style={{ width: '70%', marginRight: '20px' }}>
          <div style={{ background: '#EAEFFF', borderRadius: '12px 12px 0 0', padding: '5px 0 9px 20px', fontSize: '18px', fontWeight: 700 }}>Today's Call Status</div>
          <div>
            <PieChart highcharts={Highcharts} style={{ borderRadius: '0 0 12px 12px' }} options={options} />
          </div>
        </div>
        <Tabs className='tabPop' defaultActiveKey="1" size={"large"}>
          <TabPane tab="Recent Calls" key="1">
            <CallHistory />
          </TabPane>
          <TabPane tab="Today's Call back" key="2">
            <TodayCallBacks />
          </TabPane>
        </Tabs>
      </div>
      {/* <button onClick={() => { audio.play(); audio.loop = true }}> click </button>
    <button onClick={() => { audio.muted = true }}> stop </button> */}
    </div >
  </>
}

export default HomeView;