import axios from 'axios'
import { apiUrl, ExcelDownloadFormat } from './settings'
import {
    fullBrowserVersion,
    browserName,
    osVersion,
    osName
} from "react-device-detect";
import { saveAs } from 'file-saver';
import moment from 'moment'
let authAxios = axios.create({
    baseURL: apiUrl
})
let authAxios1 = axios.create({
    baseURL: 'http://192.168.12.18:800'
})

export const getToken = () => {
    return {
        headers: {
            Authorization: 'Token ' + localStorage.getItem('token'),
            type: "WEB",
            fullbrowserversion: fullBrowserVersion,
            browsername: browserName,
            osversion: osVersion,
            osname: osName
        }
    }
}

// export const getToken3 = () => {
//     return {
//         headers: {
//             Authorization: 'Token ' + localStorage.getItem('f123c0a191e4b1aea3f999e2e18869497b8182c9'),
//             type: "WEB",
//             fullbrowserversion: fullBrowserVersion,
//             browsername: browserName,
//             osversion: osVersion,
//             osname: osName
//         }
//     }
// }


export const getTokenFromParams = (token) => {
    return {
        headers: {
            Authorization: 'Token ' + token,
            type: "WEB",
            fullbrowserversion: fullBrowserVersion,
            browsername: browserName,
            osversion: osVersion,
            osname: osName
        }
    }
}
export const getTokenFromParamsForFile = (token) => {
    return {
        headers: {
            Authorization: 'Token ' + token,
            'content-type': 'multipart/form-data; boundary=ebf9f03029db4c2799ae16b5428b06bd',
            type: "WEB",
            fullbrowserversion: fullBrowserVersion,
            browsername: browserName,
            osversion: osVersion,
            osname: osName
        }
    }
}
class Request {

    error = err => {
        try {
            if (err.response.status === 401) {
                localStorage.clear()
                window.location.href = ''
            }
        } catch (e) {
        }
    }

    // ------------------------------------------- API Start from here --------------------------------------------- //

    login(data, recaptchaToken) {
        return new Promise((next, error) => {
            authAxios
                .post('/callers/login', data)
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    logout(data, recaptchaToken) {
        return new Promise((next, error) => {
            authAxios
                .post('/callers/logout', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    // user api

    getProfile() {
        return new Promise((next, error) => {
            authAxios
                .post('/callers/0', {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    dashboard(data) {
        return new Promise((next, error) => { //apoi ni h
            authAxios
                .post('/dashboard', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    //*Caller Details
    callerDetail(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/callers/activity/list`, { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    //*Today's Call List
    todayCallList(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/callers/today/call-list`, {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    //*Today's Dispositons
    todayDispositions(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/callers/today/dispositions`, { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    //*Call History
    callhistory(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/call/log', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    //*Preview Dialer
    previewDialer(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/callers/get/preview/number', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    //* Power Dialer
    powerDialer(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/callers/get/power/details', {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    //*Leadset Update
    leadsetUpdate(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/leadset/update/${id}`, { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    //*Leadset fetch
    leadsetFetch(id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/customer/leadset/fetch/data/${id}`, {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    //*disposition
    listDispositions(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/department/list/dispostions', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    //*Make Call
    makeCallApi(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/channel/newcall-outgoing', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    checkEndpointStatus() {
        return new Promise((next, error) => {
            authAxios
                .post('/endpoints/detail', {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getCountries(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/countries', { ...data })
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    addContact(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/customer/contacts/new', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getContacts(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/customer/contacts', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    // Callbacks
    listCallBack(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/callers/callback', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    addCallBack(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/callers/callback/new', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    //callNotes
    listCallNotes(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/callers/notes', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    addCallNotes(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/callers/notes/new', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    //*CallConference
    makeConferenceCall(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/channel/newcall-conference', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    endConferenceCall() {
        return new Promise((next, error) => {
            authAxios
                .post('/channel/newcall-conference/hangup', {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    //*Caller Availability

    updateCaller(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/callers/update/${8}`, { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    updateCallStatus(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/callers/create/activity`, { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    createLeadset(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/customer/leadset/create', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    addDnc(data) {
        return new Promise((next, error) => { //apoi ni h
            authAxios
                .post('/customer/settings/dnc/new', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    checkDnc(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/callers/check/dnc/number', { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    // getProfileFromToken(token) {
    //     return new Promise((next, error) => {
    //         authAxios
    //             .post('/customer/details', {}, getTokenFromParams(token))
    //             .then(d => {
    //                 next(d.data)
    //             })
    //             .catch(err => {
    //                 next({ error: true, err })
    //                 this.error(err)
    //             })
    //     })
    // }

}
export default new Request();