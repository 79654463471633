import React, { Suspense, lazy } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import menuData from '../../routes'
import Login from '../../containers/login'
import { componentLoader } from '../../utils'
import { Card } from 'antd'
import SuccessPage from '../success/list'
import PageNotFound from './PageNotFound'

const AuthLayout = lazy(() => componentLoader(() => import('../../layouts/authLayout')))
const BasicLayout = lazy(() => componentLoader(() => import('../../layouts/basicLayout')))

export default (props) => {
    const AppLoading = () => {
        return <Card loading={true}></Card>
    }
    return (
        <Suspense fallback={<AppLoading />}>
            <Switch>
                {menuData.map((item, key) => {

                    if (!item.children) {
                        return (<Route exact path={item.path} key={item.path} render={(route) => {
                            return <BasicLayout path={item.path} key={key} menuData={menuData} item={item}>
                                {!!item.component ?
                                    <Suspense fallback={<AppLoading />}>
                                        {item.component}
                                    </Suspense> : <></>}
                            </BasicLayout>
                        }} />)
                    }
                })}

                {menuData.map((item, key) => {
                    if (item.children) {
                        return item.children.map((child, k) => {
                            return (<Route exact path={child.path} key={child.path} render={(route) => {
                                return <BasicLayout path={child.path} menuData={menuData} item={item}>
                                    {!!child.component ?
                                        <Suspense fallback={<AppLoading />}>
                                            {child.component}
                                        </Suspense> : <></>}
                                </BasicLayout>
                            }} />)
                        })
                    }
                })}

                <Route exact path={'/'} key={'login'} render={(route) => <AuthLayout><Login /></AuthLayout>} />
                <Route exact path={'/payment/success/:id'} key={'success'} render={(route) => <SuccessPage />} />

                <Route path='*'><PageNotFound/></Route> 

            </Switch>
        </Suspense>
    )
};