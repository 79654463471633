import { notification } from 'antd';
import JsSIP from 'jssip';
import sound from '../../assets/sound.wav'

const ding = new Audio(sound);

export const makeCall = async (UA, destination, session, dispatch) => {

  // const localStream = new MediaStream();
  // console.log(UA, "UA>>>>>>>>>>")
  if (UA) {
    var options = {
      mediaConstraints: { audio: true, video: false },
      // pcConfig: {
      //   'iceServers': [
      //     { "url": "stun:stun.l.google.com:19302" },
      //     { "url": "stun:stun.counterpath.net:3478" },
      //     { "url": "stun:numb.viagenie.ca:3478" }
      //   ]
      // },
      // mediaStream: localStream,
      // iceTransportPolicy: 'all',
      // rtcpMuxPolicy: 'negotiate',
      rtcOfferConstraints: { 'offerToReceiveAudio': true },
      mandatory: [{
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: true
      },
      { 'DtlsSrtpKeyAgreement': true }]
    };
    console.log(destination, "dest>>>>>>>>>>>>FF")
    UA?.call(destination.toString(), options);
    dispatch({ type: 'SET_NUMBER', payload: destination })
    dispatch({ type: 'SET_DISPOSITION_NUMBER', payload: destination })
  }
}

export const hangupCall = async (UA, session, dispatch) => {
  if (session) {
    session.terminate();
    dispatch({ type: 'SET_NUMBER', payload: null })
    // dispatch({ type: 'SET_CALL', payload: null })
    dispatch({ type: 'OUTGOING_STATUS', payload: null })
    dispatch({ type: 'INCOMING_STATUS', payload: null })
    console.log("call terminated successfully")
  }
}
export const declineCall = async (UA, session, dispatch) => {
  console.log(session, "declineCall>>>>>>>>>>>")
  if (session) {
    session.terminate();
    dispatch({ type: 'OUTGOING_STATUS', payload: null })
    dispatch({ type: 'INCOMING_STATUS', payload: null })
    // dispatch({ type: 'SET_CALL', payload: null })
    console.log("call declined successfully")
  }
}
export const answerInCall = async (UA, session, dispatch) => {
  if (session) {
    var callOptions = {
      mediaConstraints: { audio: true, video: false },
      // mediaStream: localStream,
      // iceTransportPolicy: 'all',
      // rtcpMuxPolicy: 'negotiate',
      rtcOfferConstraints: { 'offerToReceiveAudio': true },
      mandatory: [{
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: false
      },
      { 'DtlsSrtpKeyAgreement': true }]
    };
    session?.answer(callOptions);
    console.log("call accepted")
  }
}

export const sendDtmf = async (UA, session, dest, dispatch) => {
  console.log(session, "sent DTMF", UA)
  if (session) {
    session.sendDTMF(dest);
    console.log("DTMF sent successfully")
  }
}
export const holdCall = async (UA, session, dispatch) => {
  console.log(session, "session on hold", UA)
  if (session) {
    session.hold({ useUpdate: true });
    console.log("call hold successfully")
  }
}
export const unholdCall = async (UA, session, dispatch) => {
  if (session?.isOnHold()) {
    session.unhold();
    console.log("call unhold successfully")
  }
}
export const muteCall = async (UA, session, dispatch) => {
  if (session) {
    session.mute();
    console.log("call muted successfully")
  }
}
export const unMuteCall = async (UA, session, dispatch) => {
  if (session) {
    session.unmute();
    console.log("call unmuted successfully")
  }
}

export const makeConferenceCall = async (UA, destination, session, dispatch) => {
  console.log(UA, "Conference Call UA>>>>>>>>>>")
  if (session) {
    var options = {
      mediaConstraints: { audio: true, video: false },
      // rtcOfferConstraints: { 'offerToReceiveAudio': true },
      // mandatory: [{
      //   OfferToReceiveAudio: true,
      //   OfferToReceiveVideo: true
      // },
      // { 'DtlsSrtpKeyAgreement': true }]
    };
    console.log(destination, "dest>>>>>>>>>>>>FF")
    UA?.call(destination.toString(), options);
    // session?.refer(destination.toString(), options);
    dispatch({
      type: 'SET_NUMBER',
      payload: destination
    })
  }
}


export const CreateAccount = (user, data, success, dispatch) => {
  console.log(user, "$#$$$$$$$$$")
  let endpointNumber = user?.extdetail?.extensionregid
  // let endpointNumber = '905'
  let endpointPassword = user?.extdetail?.extensionpass
  // let endpointPassword = 'a89c237c219c4162c'
  // let endpointPassword = '905'
  let domainName = user?.extdetail?.serverip?.fqdn
  // let domainName = 'td.dialmantra.biz'
  // let domainName = '192.168.10.19'

  var socket = new JsSIP.WebSocketInterface(`wss://${domainName}:8089/ws`);
  socket.via_transport = "tcp";
  var configuration = {
    uri: `sip:${endpointNumber}@${domainName}`,
    contact_uri: `sip:${endpointNumber}@${domainName}`,
    authorization_user: endpointNumber,
    port: '8089',
    register: true,
    username: `${endpointNumber}@${domainName}`,
    name: endpointNumber,
    id: endpointNumber,
    registrar_server: `${domainName}:5060`,
    session_timers: true,
    password: endpointPassword,
    sockets: [socket],
    display_name: endpointNumber,
    'session_timers': true,
    'use_preloaded_route': false,
    debug: true
  };

  JsSIP.debug.enable('JsSIP:*');
  var UA = new JsSIP.UA(configuration);

  const remoteAudio = new Audio()
  // const localStream = new MediaStream();


  UA.start();

  UA.on('registered', function (e) {
    dispatch({ type: 'UNREGISTERED', payload: null })
    dispatch({ type: 'REGIS_FAILED', payload: null })
    if (UA.isRegistered() && UA.isConnected()) {
      console.log(UA, "THIS IS UA")
      dispatch({ type: 'TERMINATE', payload: null })
      dispatch({ type: 'FAILED', payload: null })
      dispatch({
        type: 'SET_ENDPOINT',
        payload: UA
      })
      dispatch({ type: 'REGISTERED', payload: e })
      console.log("Registered", e);
      // notification.success({ message: 'Registeration successfull' })
    } else {
      notification.error({ message: 'Registeration unsuccessfull please refresh the page to register again' })
      console.log("Not Registered", e)
    }
  });

  UA.on('unregistered', function (e) {
    console.log("UA has been unregistered periodic registeration fails or UA.unregister()", e);
    dispatch({ type: 'UNREGISTERED', payload: true })
    dispatch({ type: 'SET_ENDPOINT', payload: null })
  });

  UA.on('registrationFailed', function (e) {
    console.log("register failed", e);
    dispatch({ type: 'REGIS_FAILED', payload: e })
    dispatch({ type: 'SET_ENDPOINT', payload: null })
  });

  // UA.on('registrationExpiring', function (e) {
  //   UA.register()
  // });

  UA.on('newRTCSession', (data) => {
    console.log('New RTC Session')
    const session = data.session;
    dispatch({ type: 'SET_CALL', payload: session })
    if (session.direction === 'incoming') {
      dispatch({ type: 'INCOMING_STATUS', payload: null })
      console.log('stream incoming  -------->');
      dispatch({ type: 'TERMINATE', payload: null })
      dispatch({ type: 'FAILED', payload: null })
      const sessionNumber = session?.remote_identity?._display_name == undefined ? session?.remote_identity._uri?._user : session?.remote_identity?._display_name
      dispatch({ type: 'SET_DISPOSITION_NUMBER', payload: sessionNumber })
      console.log(`session - incoming call from ${session._remote_identity}`);
      session.on('progress', (e) => {
        ding.muted = false;
        ding.play();
        console.log('Incoming call', e, session)
        dispatch({ type: 'INCOMING_STATUS', payload: 'InProgress' })
      })
      session.on('accepted', (e) => {
        console.log('incoming call accepted', e)
      })
      session.on('confirmed', (e) => {
        console.log('incoming call confirmed', e)
        if (session.isEstablished()) {
          dispatch({ type: 'SET_CALL', payload: session })
          dispatch({ type: 'SET_CALL_TIMER', payload: 0 })
          dispatch({ type: 'INCOMING_STATUS', payload: 'InConfirmed' })
          // if (e?.session?._remote_identity?._display_name) {
          // }
          ding.muted = true
        }
        else {
          dispatch({ type: 'SET_CALL', payload: null })
        }
      })
      session.on('ended', (e) => {
        console.log('incoming call ended', e)
        dispatch({ type: 'TERMINATE', payload: e })
        dispatch({ type: 'SET_NUMBER', payload: null })
        dispatch({ type: 'SET_CALL', payload: null })
        dispatch({ type: 'INCOMING_STATUS', payload: null })
        dispatch({ type: 'OUTGOING_STATUS', payload: null })
        dispatch({ type: 'SET_CONFERENCE_NUMBER', payload: null })
        ding.muted = true
      })
      session.on('failed', (e) => {
        console.log('incoming call failed', e)
        dispatch({ type: 'FAILED', payload: e })
        dispatch({ type: 'SET_CALL', payload: null })
        dispatch({ type: 'SET_NUMBER', payload: null })
        dispatch({ type: 'INCOMING_STATUS', payload: null })
        dispatch({ type: 'OUTGOING_STATUS', payload: null })
        dispatch({ type: 'SET_CONFERENCE_NUMBER', payload: null })
        ding.muted = true
      })
      session.on('peerconnection', () => {
        session.connection.addEventListener("addstream", (e) => {
          console.log(' incoming - adding audiostream ', e)
          console.log(' incoming - adding audiostream1 ', e.stream)
          remoteAudio.srcObject = e.stream;
          remoteAudio.play()
        })
      })

      // setTimeout(() => {
      //   session.answer(callOptions)
      //   console.log('call answered')
      // }, 2000);
    }

    if (session.direction === 'outgoing') {
      console.log('stream outgoing  -------->');
      console.log(session, "session")
      // session.on('progress', (e) => {
      //   console.log('call is in progress', e)
      //   dispatch({ type: 'IN_PROGRESS', payload: e })
      // })
      if (session.isInProgress()) {
        dispatch({ type: 'TERMINATE', payload: null })
        dispatch({ type: 'FAILED', payload: null })
        dispatch({ type: 'OUTGOING_STATUS', payload: 'OutProgress' })
        dispatch({ type: 'SET_CALL', payload: session })
      }
      session.on('failed', (e) => {
        console.log('call failed with cause: ' + e.cause, e)
        dispatch({ type: 'FAILED', payload: e })
        dispatch({ type: 'SET_CALL', payload: null })
        dispatch({ type: 'SET_NUMBER', payload: null })
        dispatch({ type: 'INCOMING_STATUS', payload: null })
        dispatch({ type: 'OUTGOING_STATUS', payload: null })
        dispatch({ type: 'SET_CONFERENCE_NUMBER', payload: null })
        if (e.cause === JsSIP.C.causes.SIP_FAILURE_CODE) {
          console.log('called party may not be reachable')
        }
      })
      session.on('confirmed', (e) => {
        dispatch({ type: 'SET_CALL_TIMER', payload: 0 })
        console.log('outgoing call confirmed')
        let localTracks = session.connection.getSenders();
        let streams = session.connection.getRemoteStreams();
        let localStream = session.connection.getLocalStreams()
        let streamslength = session.connection.getRemoteStreams().length;
        console.log(localTracks, 'tracks', streams, "streams>>>>><<<<<<", streamslength, "streamsLength>>>>>><<<<<<<<", localStream)
        console.log(session, "sessionbefore.............................")
        if (session.isEstablished()) {
          console.log(session, "session1.............................")
          dispatch({ type: 'SET_CALL', payload: session })
          dispatch({ type: 'OUTGOING_STATUS', payload: 'OutConfirmed' })
        } else {
          dispatch({ type: 'SET_CALL', payload: null })
        }
      })

      session.on('ended', (e) => {
        dispatch({ type: 'SET_CALL_TIMER', payload: null })
        console.log('outgoing call ended with cause :' + e.cause, e)
        dispatch({ type: 'TERMINATE', payload: e })
        dispatch({ type: 'SET_CALL', payload: null })
        dispatch({ type: 'SET_NUMBER', payload: null })
        dispatch({ type: 'INCOMING_STATUS', payload: null })
        dispatch({ type: 'OUTGOING_STATUS', payload: null })
        dispatch({ type: 'SET_CONFERENCE_NUMBER', payload: null })
      })

      session.on('peerconnection', () => console.log('outgoing - Peer connection'))

      session.connection.onaddstream = (e) => {
        console.log('outgoing - addStream 1', e);
        remoteAudio.srcObject = session.connection.getRemoteStreams()[0];
        remoteAudio.play();
      }

      session.on('getusermediafailed', function (DOMError) {
        console.log('Get User Media Failed Call Event :' + DOMError)
      })
    }
    if (session?.isEnded()) {
      dispatch({ type: 'SET_CALL', payload: null })
      dispatch({ type: 'OUTGOING_STATUS', payload: null })
      dispatch({ type: 'SET_CONFERENCE_NUMBER', payload: null })
    }
  })
}