import React, { useState } from 'react'
import { Button, Typography, Input, Space, notification, Select, Switch, Divider } from 'antd';
import './styles.less'
import { useDispatch } from 'react-redux';
import { Form } from 'antd';
import Request from '../../request';
import { push } from 'connected-react-router';
import { ArrowRightOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import _ from 'lodash'
import { ReactComponent as ImageThumb } from '../../assets/LoginPage.svg'
import { ReactComponent as Arrow } from '../../assets/arrow.svg'
import logo from '../../assets/call-center-signin.png'
import logo1 from '../../assets/dialmantra-logo-icon.png'
import logo2 from '../../assets/signinimg.png'
import { CreateAccount } from '../constant/utils.js'
const { Text } = Typography
const { Option } = Select

const Login = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [loader, setLoader] = useState(false)
    const [login, setLoginRun] = useState(true)
    const [getImg, setImg] = useState(true)

    const onFinish = async (valData) => {
        const { mobile } = valData
        valData = {
            ...valData
        }
        setLoader(true)
        let { user, token, success, message: loginMessage, data } = await Request.login(valData)
        if (success) {
            dispatch({
                type: 'SET_AUTH_TOKEN',
                payload: data.auth_token
            })
            Request.getProfile().then(({ error, data: profileData, message }) => {
                if (!error && profileData) {
                    // console.log("in login <<<<<<<<<<<<<<<<<<<<<<<<<<<<<")
                    dispatch({
                        type: 'SET_CURRENT_USER',
                        payload: { ...profileData }
                    })
                    Request.checkEndpointStatus().then(({ data, success }) => {
                        if (data.state == 'offline') {
                            // sessionStorage.removeItem("reloading");
                            dispatch(push('/dashboard'))
                            Request.updateCallStatus({ event: 'Pause' }).then(({ data, success }) => {
                                CreateAccount(profileData, data, success, dispatch)
                                notification.success({
                                    message: loginMessage
                                })
                            })
                        } else {
                            // notification.error({
                            //     message: "registering on sip server failed please logout and login again"
                            // })
                        }
                    })
                }
                else {
                    notification.error({
                        message: message || "Failed",
                        description: JSON.stringify(profileData)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')
                    })
                }
            })
        } else {
            notification.error({
                message: loginMessage || "Failed"
            })
        }
        setLoader(false)
    }

    const emailRule = [{ type: 'email', message: 'The input is not valid E-mail!' }, { required: true, message: 'Please input your E-mail!' }]
    const requiredRule = [{ min: 6, message: 'The input should be minimum 6 characters' }, { required: true, message: 'Please input your password' }]

    return (
        <div className="LoginParentDiv">
            <div className="LoginSubDiv">
                <div className="LeftSubDiv">
                    <div className="loginPageCustomer">
                        Single Platform for all Your Business Telephony Requirements!
                    </div>
                    <div style={{ fontSize: '1vw', color: 'white', width: '30vw', zIndex: 1, padding: '0px 0 0 35px', height: '30vh' }}>
                        Empower your business and employees globally with our integrated contact center application.
                    </div>
                    <ImageThumb height="65vh" style={{ alignSelf: 'end', width: '33vw', zIndex: 0, bottom: '10vh', position: 'absolute', left: '22vw' }} />
                    {getImg ? <div style={{ height: '100vh', overflow: 'hidden', alignSelf: 'end', zIndex: 1 }}><img src={logo} className='callCenterLogo' style={{ zIndex: 1, alignSelf: 'end', width: '28vw', marginRight: '4vw', verticalAlign: 'bottom' }} /></div>
                        : <div style={{ height: '100vh', overflow: 'hidden', alignSelf: 'end', zIndex: 1 }}><img src={logo2} className='callCenterLogo' style={{ zIndex: 1, alignSelf: 'end', width: '28vw', marginRight: '4vw', verticalAlign: 'bottom' }} /></div>}
                    <Arrow height="65vh" style={{ alignSelf: 'start', width: '2vw', zIndex: 0, bottom: '0vh', position: 'absolute', left: '12.5vw', height: '30vh' }} />
                    <Arrow height="65vh" style={{ alignSelf: 'start', width: '2vw', zIndex: 0, bottom: '0vh', position: 'absolute', left: '9.5vw', height: '30vh' }} />
                    <Arrow height="65vh" style={{ alignSelf: 'start', width: '2vw', zIndex: 0, bottom: '0vh', position: 'absolute', left: '6.5vw', height: '30vh' }} />
                </div>
            </div>
            <div className='loginWrapper'>
                <div className='loginContainer'>
                    <img src={logo1} className='callCenterLogo' style={{ zIndex: 1, width: '5vw' }} />
                    <div style={{ fontSize: '2.1vw', color: '#293275' }}>
                        Dialmantra
                    </div>
                    <div type={'primary'} style={{ fontSize: '0.6vw', color: '#293275' }}>
                        Powered by Collaberus Technologies
                    </div>
                    <div style={{ marginTop: '4rem' }}>
                        <Form className='form' form={form} onFinish={onFinish} size='large'>
                            <div className='inputWidth'>
                                <Form.Item name='email' style={{ marginBottom: '1.5rem' }} rules={emailRule}>
                                    <Input className="formFieldUnderline" prefix={<UserOutlined className="login-signup-icon" />} type="email" placeholder='Enter Email ID' />
                                </Form.Item>
                                <Form.Item name='password' style={{ marginBottom: '3rem' }} rules={requiredRule} hasFeedback>
                                    <Input className="formFieldUnderline" prefix={<LockOutlined className="login-signup-icon" />} maxLength={10} type="password" placeholder='Enter Password' />
                                </Form.Item>
                            </div>
                            <Form.Item style={{ marginBottom: '1rem' }}>
                                <Button size='large' loading={loader} htmlType='submit' type='secondary' style={{ backgroundColor: '#EA5B21', color: 'white', width: '20vw', marginTop: '2rem', border: 0, borderRadius: '7px' }}>Sign in</Button>
                            </Form.Item>
                        </Form>
                        <div style={{ width: '60%', position: 'relative', margin: '0 auto' }}>
                            <Divider style={{ border: '1px solid #dbdcdc' }} />
                        </div>
                    </div>

                    <div className="TermsAndCond">
                        <div className="TermData">By clicking the button above, you agree to our <Space />
                            <span className="termsOfUse">terms of use</span>
                            <Space /> <span>and</span>
                            <span className="termsOfUse"> privacy policy</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login