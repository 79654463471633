import { useState, lazy, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './styles.less'
import Request from '../../request'
import _ from 'lodash';
import { PhoneOutlined } from '@ant-design/icons';
import moment from 'moment'
import async from 'async'
import { notification, List, Avatar, Button, Skeleton, Form } from 'antd';
import FormItem from '../../components/FormItem'
import { DatePicker, Space } from 'antd';
const { RangePicker } = DatePicker;

// const Calltype = lazy(() => import('./callTypeChart'))
// const LiveCallsTable = lazy(() => import('./liveCallsTable'))
// const layout = {
//   labelCol: {
//     span: 8,
//   },
//   wrapperCol: {
//     span: 16,
//   },
// };
// const tailLayout = {
//   wrapperCol: {
//     offset: 10,
//     span: 16,
//   },
// };

const HomeView = (props) => {

    const [loading, setLoader] = useState(false);
    const [list, setDataSource] = useState('');
    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const apiFunction = async (filter) => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.listCallBack()
        setLoader(false)
        let newData = []
        if (success) {
            async.forEach(data, (val, cb) => {
                if (moment(val?.callbacktime).format('L') == moment().format('L')) {
                    newData.push(val)
                }
                cb()
            }, () => {
                if (JSON.stringify(newData) === '[]') {
                    dispatch({ type: 'SET_TODAYS_CALLBACKS', payload: false })
                }
                else {
                    dispatch({ type: 'SET_TODAYS_CALLBACKS', payload: true })
                    setDataSource(newData)
                }
            })
        } else {
            setDataSource([])

            // notification.error({
            //   message: message || "Failed"
            // })
        }
    }

    useEffect(() => {
        apiFunction()
    }, [])

    const requiredRule = [{ required: true, message: 'Please input field' }]

    const { currentUser } = useSelector((state) => ({
        currentUser: state.global.currentUser
    }))

    return <>
        <div>
            <List
                className="demo-loadmore-list"
                loading={loading}
                itemLayout="horizontal"
                // loadMore={loadMore}
                dataSource={list}
                renderItem={item => (
                    <List.Item>
                        <Skeleton avatar title={false} loading={item.loading} active>
                            <div style={{ display: 'flex', width: '75%', overflowWrap: 'anywhere', flexFlow: 'column' }}>
                                <div>{'+' + item?.phonecode + ' ' + item?.calledphone}</div>
                                <div style={{ color: '#00000073' }}>{item?.notes}</div>
                                <div style={{ color: '#00000073' }}>Call back Time : {moment.utc(item?.callbacktime).fromNow()}</div>
                            </div>
                            <Button style={{ background: '#ffffff', color: '#1E8D3E', borderRadius: '20px', width: '4vw', height: '2rem', border: '1px solid #1E8D3E' }} onClick={() => { dispatch({ type: 'SET_NUMBER_FROM_CONTACTS', payload: { phone: item?.phonecode + item?.calledphone, leadsetid: item?.leadsetid } }) }}><PhoneOutlined /></Button>
                        </Skeleton>
                    </List.Item>
                )}
            />
        </div>
    </>
}

export default HomeView;