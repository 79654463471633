import { useState, lazy, Tooltip, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './styles.less'
import Request from '../../request'
import { notification, List, Avatar, Button, Skeleton, Form } from 'antd';
import FilterTable from '../../components/Table'
import _ from 'lodash';
import { PhoneOutlined } from '@ant-design/icons';
import { ReactComponent as INCOMING } from '../../assets/incoming.svg'
import { ReactComponent as MISSED } from '../../assets/missed.svg'
import { ReactComponent as OUTGOING } from '../../assets/outgoing.svg'
import CallingInfo from '../callingSidebar/callingInfo';

import moment from 'moment'

// const Calltype = lazy(() => import('./callTypeChart'))
// const LiveCallsTable = lazy(() => import('./liveCallsTable'))

const Callhistory = (props) => {

  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser
  }))
  const dispatch = useDispatch()

  const [loading, setLoader] = useState(false);
  const [pageTotal, setPageTotal] = useState();
  const [dataSource, setDataSource] = useState('');

  const columns = [
    {
      title: 'Call Type',
      dataIndex: 'calltype',
      key: 'calltype',
      search: false,
      fixed: 'left',
      render: (val, record) => {
        return <>
          {record?.calltype == 'Inbound' ? <INCOMING onClick={() => { dispatch({ type: 'SET_NUMBER_FROM_CONTACTS', payload: { phone: record?.phone, leadsetid: record?.leadsetid } }) }} style={{ cursor: 'pointer', filter: 'drop-shadow(1px 1px 1px)' }} width={20} height={20} /> :
            record?.calltype == 'Missed' ? <MISSED onClick={() => { dispatch({ type: 'SET_NUMBER_FROM_CONTACTS', payload: { phone: record?.phone, leadsetid: record?.leadsetid } }) }} style={{ cursor: 'pointer', filter: 'drop-shadow(1px 1px 1px)' }} width={20} height={20} /> :
              <OUTGOING onClick={() => { dispatch({ type: 'SET_NUMBER_FROM_CONTACTS', payload: { phone: record?.phone, leadsetid: record?.leadsetid } }) }} style={{ cursor: 'pointer', filter: 'drop-shadow(1px 1px 1px)' }} width={20} height={20} />
          }
        </>
      }
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Created At',
      dataIndex: 'createdon',
      key: 'createdon',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.createdon.localeCompare(b.createdon),
      render: (val, r) => {
        return <>{moment.utc(val).format('DD-MM-YYYY')} &nbsp;&nbsp; {moment.utc(val).format('LTS')}</>
      }
    },

    {
      title: 'Actions',
      dataIndex: 'Action',
      key: 'Action',
      render: (val, record) => {
        return <div className="flex" style={{ justifyContent: 'center' }}>
          <PhoneOutlined className='greenCallButton' onClick={() => { dispatch({ type: 'SET_NUMBER_FROM_CONTACTS', payload: { phone: record?.phone, leadsetid: record?.leadsetid } }) }} />
        </div>
      }
    },
  ];

  const apiFunction = async (pageNum) => {
    setLoader(true)
    let { user, token, success, message, data, total } = await Request.callhistory({ ...pageNum })
    setLoader(false)
    if (success) {
      setDataSource(data)
      setPageTotal(total)
    } else {
      setDataSource([])

      // notification.error({
      //   message: message || "Failed"
      // })
    }
  }
  useEffect(() => {
    apiFunction()
  }, [])

  return <>
    <div className='headerInfo'>
      Call History
      <CallingInfo />
    </div>
    <div className='historyWrapper wrapperTop'>
      <div className="historyInner marginTop" style={{ width: '45vw', margin: '0 auto' }}>
        <FilterTable pagination={{
          onChange: page => {
            apiFunction({ page: page })
          },
          pageSize: 20,
          total: pageTotal,
          hideOnSinglePage: true,
          showSizeChanger: false
        }} bordered={false} dataSource={dataSource} size="large" columns={columns} loading={loading} scroll={{ x: 'max-content' }} />
      </div>
    </div >
  </>
}

export default Callhistory;