import { useState, lazy, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './styles.less'
import Request from '../../request'
import { notification, List, Avatar, Button, Skeleton, Form } from 'antd';
import _ from 'lodash';
import { ReactComponent as INCOMING } from '../../assets/incoming.svg'
import { ReactComponent as OUTGOING } from '../../assets/outgoing.svg'

import moment from 'moment'

// const Calltype = lazy(() => import('./callTypeChart'))
// const LiveCallsTable = lazy(() => import('./liveCallsTable'))

const CallingInfo = (props) => {

    const { currentUser, outgoingStatus, sessionCall, previewDetails, powerDetails, destination, incomingStatus, intervalId, intervalRan, displayTime } = useSelector((state) => ({
        currentUser: state.global.currentUser,
        outgoingStatus: state.call.outgoingStatus,
        destination: state.call.destination,
        incomingStatus: state.call.incomingStatus,
        previewDetails: state.global.previewDetails,
        powerDetails: state.global.powerDetails,
        sessionCall: state.call.sessionCall,
        intervalId: state.call.intervalId,
        intervalRan: state.call.intervalRan,
        displayTime: state.call.displayTime,
    }))

    var { callTimer } = useSelector((state) => ({
        callTimer: state.call.callTimer,
    }))

    const dispatch = useDispatch()

    useEffect(() => {
        if (outgoingStatus == 'OutConfirmed' || incomingStatus == 'InConfirmed' && callTimer != null) {
            if (!intervalRan) {
                var IntervalID = setInterval(() => {
                    dispatch({ type: 'SET_INTERVAL_ID', payload: IntervalID })
                    dispatch({ type: 'SET_INTERVAL_RAN_ONCE', payload: true })
                    let callTime = callTimer++
                    var myDate = new Date(null, null, null, null, null, callTime).toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1");
                    dispatch({ type: 'SET_CALL_DISPLAY_TIME', payload: myDate })
                    dispatch({ type: 'SET_CALL_TIMER', payload: callTime })
                }, 1000)
            }
        }
        else if (intervalId) {
            dispatch({ type: 'SET_CALL_DISPLAY_TIME', payload: '00:00:00' })
            clearInterval(intervalId)
            dispatch({ type: 'SET_CALL_TIMER', payload: null })
            dispatch({ type: 'SET_INTERVAL_ID', payload: null })
            dispatch({ type: 'SET_INTERVAL_RAN_ONCE', payload: false })
        }
    }, [outgoingStatus, incomingStatus])

    return <div style={{ marginLeft: '40px', display: 'flex', alignItems: 'center' }}>
        {/* {outgoingStatus == 'OutProgress' && "hello"} */}
        {(outgoingStatus == 'OutConfirmed' || outgoingStatus == 'OutProgress') && <>
            <OUTGOING style={{ filter: 'drop-shadow(1px 1px 1px)', marginRight: '10px' }} width={20} height={20} />
            <p style={{ fontSize: '25px', marginRight: '20px', marginBottom: '0', fontWeight: 900 }}>{destination}</p>
        </>
        }
        {(incomingStatus == 'InConfirmed' || incomingStatus == 'InProgress') && <>
            <INCOMING style={{ filter: 'drop-shadow(1px 1px 1px)', marginRight: '10px' }} width={20} height={20} />
            {powerDetails ?
                <p style={{ fontSize: '30px', fontSize: '25px', marginRight: '20px', marginBottom: '0', fontWeight: 900 }}>({powerDetails?.phonecode}-{powerDetails?.phonenumber})</p>
                : <p style={{ fontSize: '30px', fontSize: '25px', marginRight: '20px', marginBottom: '0', fontWeight: 900 }}>{sessionCall?.remote_identity?._display_name == undefined ? sessionCall?.remote_identity._uri?._user : sessionCall?.remote_identity?._display_name}</p>}
        </>}
        {(outgoingStatus == 'OutConfirmed' || incomingStatus == 'InConfirmed') && <>
            {/* <OUTGOING style={{ filter: 'drop-shadow(1px 1px 1px)' }} width={20} height={20} /> */}
            <p style={{ fontSize: '25px', marginRight: '20px', marginBottom: '0', fontWeight: 900, color: '#f02a2a' }}>{displayTime}</p>
        </>}
    </div>
}

export default CallingInfo;