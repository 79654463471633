import { useState, Drawer, lazy, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './styles.less'
import Request from '../../request'
import _ from 'lodash';
import { PhoneOutlined, InfoOutlined } from '@ant-design/icons';
import FilterTable from '../../components/Table'
import moment from 'moment'
import { notification, List, Avatar, Button, Skeleton, Form } from 'antd';
import FormItem from '../../components/FormItem'
import { DatePicker, Space } from 'antd';
import CallingInfo from '../callingSidebar/callingInfo';

const { RangePicker } = DatePicker;

// const Calltype = lazy(() => import('./callTypeChart'))
// const LiveCallsTable = lazy(() => import('./liveCallsTable'))
// const layout = {
//   labelCol: {
//     span: 8,
//   },
//   wrapperCol: {
//     span: 16,
//   },
// };
// const tailLayout = {
//   wrapperCol: {
//     offset: 10,
//     span: 16,
//   },
// };

const HomeView = (props) => {

    const [loading, setLoader] = useState(false);
    const [dataSource, setDataSource] = useState('');
    const [countries, setCountries] = useState([])
    const [pageTotal, setPageTotal] = useState();
    const [visible, setVisible] = useState(false)
    const [Callbacks, setCallbacks] = useState(true)
    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const columns = [
        {
            title: 'Phone Number',
            dataIndex: 'phone',
            key: 'phone',
            search: false,
            fixed: 'left',
            render: (i, val) => {
                return <>
                    {'+' + val?.phonecode + ' ' + val?.calledphone}
                </>
            }
        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            key: 'notes',
            width: 250
        },
        {
            title: 'Callback Time',
            dataIndex: 'callbacktime',
            key: 'callbacktime',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.callbacktime.localeCompare(b.callbacktime),
            render: (val, r) => {
                return <>{moment.utc(val).format('DD-MM-YYYY')} &nbsp;&nbsp; {moment.utc(val).format('LTS')}</>
            }
        },
        {
            title: 'Created At',
            dataIndex: 'createdon',
            key: 'createdon',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.createdon.localeCompare(b.createdon),
            render: (val, r) => {
                return (moment.utc(val).format('DD-MM-YYYY'))
            }
        },

        {
            title: 'Actions',
            dataIndex: 'Action',
            key: 'Action',
            render: (val, record) => {
                return <><div className="flex" style={{ justifyContent: 'center' }}>
                    <PhoneOutlined className='greenCallButton' onClick={() => { dispatch({ type: 'SET_NUMBER_FROM_CONTACTS', payload: { phone: record?.phonecode + record.calledphone, leadsetid: record?.leadsetid } }) }} />
                    {/* <InfoOutlined style={{ color: "primary", fontSize: 20 }} onClick={() => { setVisible(true) }} /> */}
                </div>
                </>
            }
        },
    ];

    // const column1 = [
    //     {
    //         title: 'Notes',
    //         dataIndex: 'notes',
    //         key: 'notes',
    //     },
    // ];

    const apiFunction = async (pageNum) => {
        setLoader(true)
        let { user, token, success, message, data, total } = await Request.listCallBack({ ...pageNum })
        setLoader(false)
        if (success) {
            setDataSource(data)
            setPageTotal(total)
        } else {
            setDataSource([])

            // notification.error({
            //   message: message || "Failed"
            // })
        }
    }

    const onFinish = async (valData) => {
        // valData.callbacktime = moment(valData.callbacktime, 'DD/MM/YYYY').format('YYYY-MM-DDT00:00:00.000')
        valData.time = moment(valData?.time).toISOString().split('T')[1]
        setLoader(true)
        let { user, token, success, message, data } = await Request.addCallBack(valData)
        // console.log(success, data, message)
        setLoader(false)
        if (success) {
            notification.success({
                message: message
            })
            form.resetFields()
            apiFunction()
            setCallbacks(true)
        } else {
            notification.error({
                message: message || "Failed",
                description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')
            })
        }
    }

    useEffect(() => {
        apiFunction()
        form.setFieldsValue({ country: 'IN' });
    }, [])

    // const range = (start, end) => {
    //     const result = [];

    //     for (let i = start; i < end; i++) {
    //         result.push(i);
    //     }

    //     return result;
    // }; // eslint-disable-next-line arrow-body-style

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current < moment().startOf('day');
    };

    // const disabledDateTime = () => ({
    //     disabledHours: () => range(0, 24).splice(4, 20),
    //     disabledMinutes: () => range(30, 60),
    //     disabledSeconds: () => [55, 56],
    // });

    const requiredRule = [{ required: true, message: 'Please input field' }]

    const { currentUser } = useSelector((state) => ({
        currentUser: state.global.currentUser
    }))

    return <div>
        <div className='headerInfo'>
            Callbacks
            <CallingInfo />
        </div>
        <div className='wrapperTop'>
            <div className="buttonDiv1">
                <div className={Callbacks ? 'selectedDiv' : 'unselectedDiv'}>
                    <Button size="large" ghost={!Callbacks} type={Callbacks ? "primary" : "secondary"} shape="round"
                        onClick={() => { setCallbacks(true) }} className={Callbacks ? 'selectedDivButton' : 'unselectedDivButton1'}>Callbacks</Button>
                </div>
                <div className={!Callbacks ? 'selectedDiv' : 'unselectedDiv'}>
                    <Button size="large" ghost={Callbacks} type={!Callbacks ? "primary" : "secondary"} shape="round"
                        onClick={() => { setCallbacks(false); form.setFieldsValue({ country: 'IN' }) }} className={!Callbacks ? 'selectedDivButton' : 'unselectedDivButton1'}>Add Callbacks</Button>
                </div>
            </div>
            {Callbacks && <FilterTable bordered={false} pagination={{
                onChange: page => {
                    apiFunction({ page: page })
                },
                pageSize: 20,
                total: pageTotal,
                hideOnSinglePage: true,
                showSizeChanger: false
            }} dataSource={dataSource} size="large" columns={columns} loading={loading} scroll={{ x: 'max-content' }} />
            }
            {!Callbacks && <div className='buttonSubmit'>
                <Form
                    // {...layout}
                    form={form}
                    onFinish={onFinish}
                >

                    <FormItem
                        label={'Phone Code'}
                        name="phonecode"
                        rules={requiredRule}
                    />
                    <FormItem
                        label={'Phone Number'}
                        name="calledphone"
                        maxLength={11}
                        rules={requiredRule}
                    />
                    <FormItem
                        label={'Select Callback Date'}
                        name="callbacktime"
                        inputType={'DATE'}
                        mode="date"
                        className='callbacktimeD'
                        width={'auto'}
                        format={'DD/MM/YYYY'}
                        placeholder="select date"
                        disabledDate={disabledDate}
                        rules={requiredRule}
                    />
                    <FormItem
                        label={'Select Callback Time'}
                        name="time"
                        inputType={'TIME'}
                        className='callbacktimeD'
                        mode="time"
                        format={'HH:mm:ss'}
                        placeholder="select time"
                        // disabledTime={disabledDateTime}
                        rules={requiredRule}
                    />
                    <FormItem
                        label={'Notes'}
                        name="notes"
                        initialValues="IN"
                        inputType={'TEXTAREA'}
                        rules={requiredRule}
                    />
                    <FormItem
                        inputType={'BUTTON'}
                        type="primary"
                        buttonStyle={{ width: 200, marginTop: 20 }}
                        loading={loading}
                        title="SUBMIT"
                        htmlType="submit" />
                </Form>
            </div>}

        </div >
        {/* <Drawer
            title="Leadset Data"
            placement="right"
            closable={true}
            className='drawerCross'
            width={500}
            onClose={() => setVisible(false)}
            visible={visible}
        > */}
        {/* <FilterTable bordered dataSource={dataSource} size="large" columns={column} loading={loading} scroll={{ x: 'max-content' }} /> */}
        {/* </Drawer> */}
    </div>
}

export default HomeView;