import { useState, lazy, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './styles.less'
import Request from '../../request'
import _ from 'lodash';
import { push } from 'connected-react-router';
import { Modal, Collapse, Select, Form, Input, Button, notification, Card, Divider } from 'antd';
import FormItem from '../../components/FormItem'
import moment from 'moment'
import inform from '../../assets/callAssets/inform.png'
import async from 'async'
import downArrow from '../../assets/callAssets/downArrow.png'
import CallingInfo from '../callingSidebar/callingInfo';

const Call = (props) => {
  const [form] = Form.useForm()
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loader, setLoader] = useState(false)
  const [filters, setFilters] = useState(false)
  const [disposition, setDisposition] = useState()
  const [callHistoryData, setCallHistoryData] = useState()
  const [callNotesValue, setCallNotesValue] = useState(false)
  const [afterAddDisp, setAfterAddDisp] = useState(false)
  const [callNotesData, setCallNotesData] = useState()
  const [Callnotes, setCallnotes] = useState(true)
  const [callerStatusAlreadyFalse, setCallerStatusAlreadyFalse] = useState()
  const requiredRule = [{ required: true, message: 'Please input field' }]
  const { currentUser, previewDetails, terminated, dispositionNumber, readyPauseStatus, failed, destination } = useSelector((state) => ({
    currentUser: state.global.currentUser,
    previewDetails: state.global.previewDetails,
    terminated: state.call.terminated,
    dispositionNumber: state.call.dispositionNumber,
    readyPauseStatus: state.call.readyPauseStatus,
    failed: state.call.failed,
    destination: state.call.destination,
  }))
  const dispatch = useDispatch()
  const { Panel } = Collapse;

  const { TextArea } = Input;
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  useEffect(() => {
    if (previewDetails) {
      let mainData = previewDetails
      mainData = {
        ...mainData,
        ...mainData.extra_detail,
      }
      form.setFieldsValue({ ...mainData })
      // focusInput()
    }
  }, [previewDetails])

  useEffect(() => {
    saveCallNotes()
    setCallNotesData()
    setCallHistoryData()
    onChangeValue(readyPauseStatus)
    if (previewDetails == null && terminated != null) {
      if (currentUser?.department?.isdispositions) {
        apiFunction()
      }
      else {
        dispatch({ type: 'TERMINATE', payload: null })
        dispatch({ type: 'FAILED', payload: null })
        dispatch(push('/'))
      }
    }

    if (previewDetails != null && terminated != null) {
      onFinish(form.getFieldsValue())
      if (currentUser?.department?.isdispositions) {
        apiFunction()
      }
      else {
        dispatch({ type: 'SET_PREVIEW_DETAILS', payload: null })
        dispatch({ type: 'SET_POWER_DETAILS', payload: null })
        dispatch({ type: 'TERMINATE', payload: null })
        dispatch({ type: 'FAILED', payload: null })
        dispatch(push('/'))
      }
    }
  }, [terminated])

  const callHistory = async (callNum) => {
    setLoader(true)
    let { user, token, success, message, data } = await Request.callhistory({ number: callNum })
    setLoader(false)
    if (success) {
      setCallHistoryData(data)
    } else {
      setCallHistoryData([])
    }
  }

  const callNotes = async (callNum) => {
    setLoader(true)
    let { user, token, success, message, data, total } = await Request.listCallNotes({ number: callNum })
    setLoader(false)
    if (success) {
      setCallNotesData(data)
    } else {
      setCallNotesData([])
    }
  }

  useEffect(() => {
    if (destination != null) {
      callHistory(destination)
      callNotes(destination)
    }
  }, [destination])

  useEffect(() => {
    onChangeValue(readyPauseStatus)
    if (previewDetails == null && (failed?.cause == 'Canceled' || failed?.cause == 'Rejected')) {
      if (currentUser?.department?.isdispositions) {
        apiFunction()
      }
      else {
        dispatch({ type: 'SET_PREVIEW_DETAILS', payload: null })
        dispatch({ type: 'SET_POWER_DETAILS', payload: null })
        dispatch({ type: 'TERMINATE', payload: null })
        dispatch({ type: 'FAILED', payload: null })
        dispatch(push('/'))
      }
    }
  }, [failed])

  const onChangeValue = async (v) => {
    setLoader(true)
    let { success, message, data } = await Request.updateCaller(currentUser?.id, { callerstatus: v == true ? 'active' : 'inactive' })
    setLoader(false)
    if (success) {
      dispatch({
        type: 'SET_CURRENT_USER',
        payload: { ...data }
      })
    } else {
      notification.error({
        message: message || 'Error'
      });
    }
  }

  const apiFunction = async (valData) => {
    setLoader(true)
    let { user, token, success, message, data } = await Request.listDispositions(valData)
    setLoader(false)
    if (success) {
      let add = []
      async.forEach(data, (value, cb) => {
        if (value?.status == true) {
          add.push(value)
        }
        cb()
      }, () => {
        setDisposition(() => add)
      })
    } else {
      setDisposition(() => [])
    }
  }

  const addDisposition = async (val) => {
    const value = {
      phonenumber: dispositionNumber,
      called: true,
      status: val?.id,
      extra_detail: {
        customer: val?.customer,
        caller: currentUser?.id
      }
    }
    if (val?.name.toString().toLowerCase() == 'dnc') {
      setLoader(true)
      let { success, message, data } = await Request.addDnc({ number: dispositionNumber, customerid: val?.customer })
      setLoader(false)
      if (success) {
        notification.success({
          message: message || 'Number added to dnc'
        });
      }
    }
    setLoader(true)
    let { success, message, data } = await Request.createLeadset({ ...value })
    setLoader(false)
    if (success) {
      dispatch({ type: 'SET_PREVIEW_DETAILS', payload: { ...data } })
      setAfterAddDisp(value)
    } else {
      notification.error({
        message: message || 'Error'
      });
    }
  }

  useEffect(() => {
    if (afterAddDisp) {
      console.log(afterAddDisp, 'afterAddDisp')
      updateDisposition({id: afterAddDisp?.status})
      setAfterAddDisp(false)
    }
  }, [afterAddDisp])


  useEffect(() => {
    if (terminated != null || failed != null) {
      if (disposition?.length == undefined || disposition?.length == 0) {
        dispatch({ type: 'SET_PREVIEW_DETAILS', payload: null })
      }
      else {
        setIsModalVisible(true)
      }
    }
  }, [disposition])

  const onFinish = async (valData) => {
    valData = {
      extra_detail: {
        ...valData,
        customer: currentUser?.balance[0]?.customer,
        caller: currentUser?.id
      },
      leadset: true
    }
    setLoader(true)
    let { user, token, success, message, data } = await Request.leadsetUpdate(previewDetails?.id, { ...valData })
    setLoader(false)
    if (success) {
      // notification.success({
      //   message: message
      // })
    }
    else {
      notification.error({
        message: `${message}` || "Failed"
      })
    }
  }

  const updateDisposition = async (valData) => {
    console.log(valData, 'valDataaaaaaaaaaa')
    valData = {
      called: true,
      status: valData?.id,
      leadset: true
    }
    setLoader(true)
    let { user, token, success, message, data } = await Request.leadsetUpdate(previewDetails?.id, { ...valData })
    setLoader(false)
    if (success) {
      dispatch({ type: 'SET_PREVIEW_DETAILS', payload: null })
      dispatch({ type: 'SET_DISPOSITION_NUMBER', payload: null })
      dispatch({ type: 'TERMINATE', payload: null })
      dispatch({ type: 'FAILED', payload: null })
      setIsModalVisible(false)
      notification.success({
        message: message
      })
      dispatch(push('/'))
    }
    else {
      notification.error({
        message: `${message}` || "Failed"
      })
    }
  }

  const saveCallNotes = async () => {
    const valData = {
      calledphone: previewDetails?.phonenumber,
      phonecode: previewDetails?.phonecode,
      notesdata: callNotesValue
    }
    if (!valData.calledphone || !valData.phonecode || valData.notesdata === false) {
      setCallNotesValue(false)
      return
    }
    setLoader(true)
    let { user, token, success, message, data } = await Request.addCallNotes({ ...valData })
    setLoader(false)
    if (success) {
      notification.success({
        message: message
      })
      setCallNotesValue(false)
      apiFunction()
    } else {
      notification.error({
        message: message || "Failed",
        description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')
      })
    }
  }

  return <>
    <div className='headerInfo'>
      Customer Information
      <CallingInfo />
    </div>
    <div className="flexCall" style={{ minHeight: 'calc(100vh - 6.5em)' }}>
      <div className='information1'>
        {previewDetails && <>
          <h3 style={{ textAlign: 'left' }}>Customer Information <span style={{ fontWeight: 700 }}>({previewDetails?.phonecode}-{previewDetails?.phonenumber})</span></h3>
          <Divider style={{ margin: '0 0 12px 0' }} />
          <Form
            {...layout}
            layout="vertical"
            form={form}
            onFinish={onFinish}
          >
            {_.map(previewDetails?.extra_detail, (val, k) => {
              if (k == 'customer' || k == 'caller') { return; }
              return <>
                <FormItem
                  label={k}
                  // ref={inputElement}
                  name={k}
                  // rules={requiredRule}
                  inputType={'TEXTAREA'}

                />
              </>
            }
            )}
            <div style={{ display: 'block', gridColumn: '1/4', marginTop: '20px', margin: '0 auto', width: '100%' }}>
              <FormItem
                inputType="BUTTON"
                type="primary"
                buttonStyle={{ width: 180, marginTop: 10 }}
                loading={loader}
                title="SUBMIT"
                htmlType="submit" />
            </div>
          </Form>
        </>}
      </div>
      <Modal title="Diposition" bodyStyle={{ borderRadius: '5px' }} className='dispositionModal' width={400} destroyOnClose={true} visible={isModalVisible} closable={false} footer={null} onOk={() => { setIsModalVisible(true) }} onCancel={() => { setIsModalVisible(true) }}>
        <div className="" style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
          {_.map(disposition, val => {
            return <>
              <Button loading={loader} onClick={() => { previewDetails != null ? updateDisposition(val) : addDisposition(val) }} style={{ background: '#1E8D3E', color: 'white', borderRadius: '50px' }}>{val?.name}</Button>
            </>
          })}
        </div>
      </Modal>
      <div className='information2' >
        {/* <h3 style={{ textAlign: 'left' }}><img src={downArrow} style={{ alignSelf: 'center', marginRight: '10px' }} />Call Notes<img src={inform} style={{ alignSelf: 'center', marginLeft: '10px' }} /></h3> */}
        <div className='section1'>
          <Collapse>
            <Panel header="Call Notes" key="1">
              <TextArea rows={4} allowClear={true} value={callNotesValue} onChange={(e) => setCallNotesValue(e.target.value)} placeholder="Type Here" style={{ marginTop: "2px" }} />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button type='secondary' onClick={() => setCallNotesValue(false)} style={{ border: '1px solid rgb(234, 91, 33)', color: 'black', width: '6vw', marginTop: '1rem', borderRadius: '7px' }}>Clear</Button>
                <Button type='secondary' onClick={saveCallNotes} style={{ backgroundColor: '#EA5B21', color: 'white', width: '6vw', marginTop: '1rem', border: 0, borderRadius: '7px' }}>Save</Button>
              </div>
            </Panel>
          </Collapse>
        </div>
        <div className='section2'>
          <div className="buttonDiv1">
            <div className={Callnotes ? 'selectedDiv' : 'unselectedDiv'}>
              <Button size="large" ghost={!Callnotes} type={Callnotes ? "primary" : "secondary"} shape="round"
                onClick={() => { setCallnotes(true) }} className={Callnotes ? 'selectedDivButton' : 'unselectedDivButton1'}>History</Button>
            </div>
            <div className={!Callnotes ? 'selectedDiv' : 'unselectedDiv'}>
              <Button size="large" ghost={Callnotes} type={!Callnotes ? "primary" : "secondary"} shape="round"
                onClick={() => { setCallnotes(false); form.setFieldsValue({ country: 'IN' }) }} className={!Callnotes ? 'selectedDivButton' : 'unselectedDivButton1'}>Call Notes</Button>
            </div>
          </div>
          {Callnotes && <>
            <div className='HistoryWrapper'>
              {_.map(callHistoryData, val => {
                return <>
                  <div className='text'>{val?.deptname}<span className='text1'> ({val?.calltype}) ({val?.callstatus})</span>
                    <p className='subtext'>{moment.utc(val?.createdon).format('LLL')}  ({val?.callduration})</p>
                    <Divider style={{ margin: '12px 0' }} />
                  </div>
                </>
              })}
            </div>
          </>}
        </div>
      </div>
    </div>
  </>
}

export default Call;




// import { useState, lazy, useEffect, useRef } from 'react';
// import { useSelector } from 'react-redux';
// import './styles.less'
// import _, { indexOf, lastIndexOf } from 'lodash';
// import { Card, Space, Carousel, Image, Radio, message, Input, Modal, Tabs, Button, Divider, InputNumber } from 'antd';
// import Information from './information'
// import History from './history'
// import callIcon from '../../assets/callAssets/callIcon.png'
// import hold from '../../assets/callAssets/hold.png'
// import mute from '../../assets/callAssets/mute.png'
// import conference from '../../assets/callAssets/conference.png'
// import keypad from '../../assets/callAssets/keypad.png'
// import endCall from '../../assets/callAssets/endCall.png'
// import downArrow from '../../assets/callAssets/downArrow.png'
// import cancelNumber from '../../assets/callAssets/cancelNumber.png'
// import JsSIP from 'jssip';

// const { TabPane } = Tabs;

// // const Calltype = lazy(() => import('./callTypeChart'))
// // const LiveCallsTable = lazy(() => import('./liveCallsTable'))

// const Call = (props) => {
//   const { currentUser } = useSelector((state) => ({
//     currentUser: state.global.currentUser
//   }))

//   const callback = (key) => {
//     // console.log(key);
//   }

//   return <>
//       <div className="HomeTabs">
//         {!<div className='popTop'>
//           <div className='popTopL'>
//             <img src={callIcon} className='callCenterLogo' style={{ alignSelf: 'center' }} />
//             <div>
//               <p style={{ color: '#EA5B21', fontSize: '1vw', fontWeight: 600, textAlign: 'left' }}>Live Call</p>
//               <p style={{ color: '#0C547D', fontSize: '1.7vw', float: 'left' }}>Natallia Young</p>
//               <p style={{ color: '#454746', fontSize: '1vw', bottom: '-12px', position: 'relative', display: 'initial', marginLeft: '10px' }}>(650) 312-5590</p>
//             </div>
//           </div>
//           <div className='popTopR'>
//             <p style={{ color: '#454746', fontSize: '1vw', marginRight: '30px' }}>03:03</p>
//             <Button type='secondary' style={{ backgroundColor: '#EA5B21', color: 'white', marginTop: '1rem', border: 0, borderRadius: '7px' }}>Select Disposition</Button>
//           </div>
//         </div>}

//         {/* <Tabs className='tabPop' defaultActiveKey="1" onChange={callback} size={"large"}>
//           <TabPane tab="Customer Information" key="1">
//             <Information />
//           </TabPane>
//           <TabPane tab="Customer History" key="2">
//             <History />
//           </TabPane>
//         </Tabs> */}
//       </div>
//   </>
// }

// export default Call;